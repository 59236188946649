














































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";

import { ProductCategoryDto, WarehouseDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView.vue";
import WarningSet from "@/views/warehouse/warehouseSearch/warningSet.vue";
import { createNgTree, INgNode } from "@/utils/tree";

@Component({
  name: "ProductSkuList",
  components: {
    WarningSet,
    PagedTableView,
  },
})
export default class ProductSkuList extends Vue {
  editId = 0;
  queryForm = {
    model: undefined,
    number: undefined,
    fkCategory: undefined,
    name: undefined,
    parentIdPath: undefined,
  };
  categories: ProductCategoryDto[] = [];
  categoriesTree: INgNode<ProductCategoryDto>[] = [];

  // 获取表数据
  fetchData(params: any) {
    console.log("parentIdPath=" + this.queryForm.parentIdPath);
    return api.productSku.getAll(params);
  }

  created() {
    this.fetchCategories();
  }

  fetchCategories() {
    api.productCategory.getAll({ maxResultCount: 1000 }).then((res) => {
      this.categories = res.items!;
      this.categoriesTree = createNgTree(
        res.items!,
        "parentId",
        "id",
        null,
        "children",
        null,
        false,
        "id",
        "name"
      );
    });
  }

  // 预警设置
  handleWarningSet(index: number, row: WarehouseDto) {
    this.editId = row!.id!;
    (this.$refs.editForm as any).show = true;
    (this.$refs.editForm as any).form = row;
  }

  handleCategoriesChange(value: any) {
    this.queryForm!.parentIdPath = value![value.length - 1];
  }
}
