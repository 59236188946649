










































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import api from "@/api";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "WarningSet",
})
export default class WarningSet extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: number;
  loading = true;
  show = false;
  form = {
    id: undefined,
    earlyWarningStock: undefined,
    quantity: undefined,
  };

  save() {
    (this.dataForm as any).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      if (this.dataId) {
        api.productSku
          .setEarlyWarningStock({
            body: {
              id: this.form.id,
              quantity: this.form.quantity,
            },
          })
          .then(() => {
            this.show = false;
            this.$message.success((this as any).$l.getLanguageText("basic.updateSuccess").toString());
          });
      }
    });
  }

  @Watch("show")
  async onShowChange() {
    if (this.dataId) {
      this.loading = true;
      await api.productSku
        .get({ id: this.dataId })
        .then((res: any) => {
          this.form.id = res!.id;
          this.form.earlyWarningStock = res!.earlyWarningStock;
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  linkRule = {
    quantity: [
      {
        required: true,
        message: (this as any).$l.getLanguageText("wareHouseSearch.tips.quantity"),
        trigger: "blur",
      },
    ],
  };

  get title() {
    return (this as any).$l.getLanguageText("wareHouseSearch.warningSet").toString();
  }

  cancel() {
    this.show = false;
  }
}
